import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ArrayHelpers } from 'helpers/arrayHelpers';
import { CURRENTLY_SELECTED_VARIANT, RECENTLY_VIEWED } from 'helpers/constants/localStorage';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { useAccount, useConfig, useProduct } from 'frontastic';
import CTProductListCard from '../../ct-product-list/ct-product-list-card';
import { gtmViewPromotion } from 'helpers/utils/gtm-events/gtmEcommerce';

const RecentlyViewed = ({ currentProductId }) => {
  const [recentlyViewedProductsSkus, setRecentlyViewedProductsSkus] = useState([]);
  const [recentlyViewedProducts, setRecentlyViewedProducts] = useState([]);
  const { getProduct } = useProduct();
  const router = useRouter();

  // TODO possibly delay call to onVisible(element, () => {gtmViewPromotion...} )
  gtmViewPromotion(
    mapLocaleToMeaningfulFormat(router.locale)?.recentlyViewed,
    currentProductId,
    recentlyViewedProducts,
  );

  useEffect(() => {
    const isRecentlyViewPresent: any = JSON.parse(localStorage?.getItem(RECENTLY_VIEWED));
    setRecentlyViewedProductsSkus(isRecentlyViewPresent);
  }, [currentProductId]);

  const getProductData = async (skusArray) => {
    setRecentlyViewedProducts([]);
    skusArray = skusArray?.filter((obj) => obj != undefined);
    const productDataArray = await Promise.all(
      skusArray?.map(async (id) => {
        if (id !== localStorage?.getItem(CURRENTLY_SELECTED_VARIANT) && id) {
          const currentProductData = await getProduct({ sku: id });
          return currentProductData;
        }
        return null;
      }),
    );

    const arrayWithoutDuplicates = ArrayHelpers?.removeDuplicatesById(productDataArray);
    const filteredProductDataArray = arrayWithoutDuplicates.filter(
      (productData) =>
        productData !== null &&
        productData?.productId !== localStorage?.getItem(CURRENTLY_SELECTED_VARIANT)?.split(' ')[0],
    );
    setRecentlyViewedProducts([].concat(...filteredProductDataArray));
  };

  useEffect(() => {
    const isRecentlyViewPresent: any = JSON.parse(localStorage?.getItem(RECENTLY_VIEWED));
    if (isRecentlyViewPresent?.length > 0) {
      getProductData(recentlyViewedProductsSkus);
    }
  }, [recentlyViewedProductsSkus]);

  return (
    <section>
      <ul className="grid w-full grid-cols-2 gap-x-5 gap-y-[30px] md:gap-x-[27px] lg:grid-cols-4">
        {recentlyViewedProducts?.map((product, index) => {
          if (index < 4) {
            return <CTProductListCard data={product} key={index} positions={index} source="recently-viewed" />;
          }
        })}
      </ul>
    </section>
  );
};

export default RecentlyViewed;
