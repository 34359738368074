import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useRouter } from 'next/router';
import ProductRichText from 'components/seo/productRichText';
import Breadcrumbs from 'components/sol-ui/cms/Breadcrumbs';
import {
  CURRENTLY_SELECTED_VARIANT,
  CURRENT_PATH,
  PREVIOUS_PATH,
  RECENTLY_VIEWED,
} from 'helpers/constants/localStorage';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { RouteHelpers } from 'helpers/routeHelpers';
import { StringHelpers } from 'helpers/stringHelpers';
import { CLEARANCE_CATEGORY_SLUG_PREFIX } from 'helpers/utils/constant';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { desktop } from 'helpers/utils/screensizes';
import { useAccount, useProduct } from 'frontastic';
import YouMayLike from '../no-search-results/you-may-like-section/you-may-like-item';
import ImageGalleryCarousel from './image-gallery-carousel';
import ProductDescription from './pdp-description';
import PDPSocialLinks from './pdp-social-link';
import RecentlyViewed from './recently-viewed';
import ShopCollection from './shop-collection';
import { gtmViewItem } from '../../../../helpers/utils/gtm-events/gtmEcommerce';

const PDP = ({
  isQuickView = false,
  productId = null,
  productDataSource = null,
  pdpSelectedVariant,
  algolia = null,
  dataSource = null,
  optionalAccessory = null,
  setNavigationLink,
  closePlpQuickViewModal,
  pdpNavigationLink,
  isYMALEnabled,
  isShopCollectionEnabled,
}) => {
  //next/router
  const router = useRouter();
  const [isDesktop] = useMediaQuery(desktop);
  const { color } = router?.query;
  const { size } = router?.query;
  const { getProduct } = useProduct();
  const [breadCrumbData, setBreadCrumbData] = useState(null);
  const [productData, setProductData] = useState<any>(null);
  const [selectedVariant, setSelectedVariant] = useState(pdpSelectedVariant) as any;
  const [selectedCarouselImage, setSelectedCarouselImage] = useState<any>(null);
  const [isData, setIsData] = useState(true);
  const [isShopData, setIsShopData] = useState(true);
  const [isShowViewAll, setIsShowViewAll] = useState(true);
  const [collection, setCollection] = useState('');
  const [term, setTerm] = useState(
    router?.query?.currentNav ?? productData?.categories ? productData?.categories[0]?.slug : '',
  );
  const [trustPilotSkuId, setTrustPilotSkuId] = useState('');
  const [variantSelection, setVariantSelection] = useState(true);

  const [recentlyViewedProducts, setRecentlyViewedProducts] = useState([]);
  const [currentProductID, setCurrentProductID] = useState('');
  const [canShowRecentlyViewed, setCanShowRecentlyViewed] = useState(true);
  const isMounted = useRef(true);
  const [currentSlug, setCurrentSlug] = useState();
  const [isDuplicateSKU, setIsDuplicateSKU] = useState(true);
  const [isClearanceCategory, setIsClearanceCategory] = useState(false);
  const { account } = useAccount();

  const [qtyFromChild, setQtyFromChild] = useState(1);

  function handleQtyFromChild(data) {
    setQtyFromChild(data);
  }

  const selectVariant = useCallback(
    (productData) => {
      if (productData) {
        let clearanceCategory;

        if (isQuickView) {
          clearanceCategory = localStorage?.getItem(CURRENT_PATH).includes(CLEARANCE_CATEGORY_SLUG_PREFIX);
        } else {
          clearanceCategory = localStorage?.getItem(PREVIOUS_PATH)?.includes(CLEARANCE_CATEGORY_SLUG_PREFIX);
        }
        setIsClearanceCategory(clearanceCategory);
        if (clearanceCategory) {
          const clearanceVariant = productData?.variants?.find((variant: any) => variant?.originalPrice !== undefined);
          setSelectedVariant(clearanceVariant);
        } else {
          setIsClearanceCategory(false);
        }
        if (color !== undefined || pdpSelectedVariant != undefined || !clearanceCategory) {
          const filteredVariant = productData?.variants?.find((variant) => {
            if (variant?.attributes?.size) {
              return (
                (StringHelpers?.removeWhiteSpace(variant?.attributes?.actualColor)?.toLowerCase() ===
                  StringHelpers?.removeWhiteSpace(color)?.toLowerCase() &&
                  StringHelpers?.removeWhiteSpace(variant?.attributes?.size)?.toLowerCase() ===
                    StringHelpers?.removeWhiteSpace(size)?.toLowerCase()) ||
                variant?.sku === pdpSelectedVariant?.sku
              );
            } else {
              return (
                StringHelpers?.removeWhiteSpace(variant?.attributes?.actualColor)?.toLowerCase() ===
                  StringHelpers?.removeWhiteSpace(color)?.toLowerCase() || variant?.sku === pdpSelectedVariant?.sku
              );
            }
          });
          if (!filteredVariant && productDataSource) {
            if (clearanceCategory) {
              const clearanceVariant = productData?.variants?.find(
                (variant: any) => variant?.originalPrice !== undefined,
              );
              setSelectedVariant(clearanceVariant);
            } else {
              setSelectedVariant(productDataSource?.variants[0]);
            }
          } else {
            if (filteredVariant && !clearanceCategory) {
              setSelectedVariant(filteredVariant);
            }
          }
        } else {
          if (productDataSource) {
            setSelectedVariant(productDataSource?.variants[0]);
          }
        }
      }
    },
    [pdpSelectedVariant, productDataSource?.variants],
  );
  const fetchProduct = useCallback(async () => {
    if (productId) {
      const product = await getProduct({ id: productId });
      setProductData(product);
      selectVariant(product);
    }
    if (productDataSource && !productId) {
      setProductData(productDataSource);
      selectVariant(productDataSource);
    }
  }, [productId, productDataSource, getProduct, selectVariant]);

  useEffect(() => {
    let clearanceCategory = localStorage?.getItem(PREVIOUS_PATH)?.includes(CLEARANCE_CATEGORY_SLUG_PREFIX);
    if (isQuickView) {
      clearanceCategory = localStorage?.getItem(CURRENT_PATH).includes(CLEARANCE_CATEGORY_SLUG_PREFIX);
    }

    if (clearanceCategory) {
      setIsClearanceCategory(true);
      const clearanceVariant = productData?.variants?.find((variant: any) => variant?.originalPrice !== undefined);
      setSelectedVariant(clearanceVariant);
    } else {
      setIsClearanceCategory(false);
    }
  }, [productData]);

  useEffect(() => {
    setProductData(null);
    fetchProduct();
  }, [fetchProduct]);

  useEffect(() => {
    let pdpNavigationLink = `${routerURL.products}/${productData?.slug}`;
    if (!selectedVariant && productData && productData?.variants?.[0]) {
      pdpNavigationLink += `?color=${productData?.variants[0]?.all_Attributes?.actualColor}`;
    }

    if (selectedVariant) {
      const color = selectedVariant?.attributes?.actualColor;
      const size = selectedVariant?.attributes?.size;
      if (color !== undefined) {
        pdpNavigationLink += `?color=${color}`;
      }
      if (size !== undefined) {
        pdpNavigationLink += `&size=${size}`;
      }
    }

    if (setNavigationLink != undefined) {
      setNavigationLink(pdpNavigationLink);
    }
  }, [productData, selectedVariant, setNavigationLink]);

  useEffect(() => {
    const currentNav = localStorage.getItem('currentNav');
    if (productData && currentNav) {
      const breadCrumbData = productData?.categories?.find((item) => item?.slug == currentNav)?.breadCrumb;
      if (breadCrumbData) {
        setBreadCrumbData(breadCrumbData);
      }
    } else {
      if (productData && productData?.categories) {
        setBreadCrumbData(productData?.categories[0]?.breadCrumb);
      }
    }
    setTerm(router?.query?.currentNav ?? productData?.categories ? productData?.categories[0]?.slug : '');
  }, [productData]);

  useEffect(() => {
    setTrustPilotSkuId(StringHelpers.removeWhiteSpace(selectedVariant?.sku));
    if (selectedVariant?.sku !== null || selectedVariant?.sku !== undefined) {
      localStorage.setItem(CURRENTLY_SELECTED_VARIANT, selectedVariant?.sku);
    }
    if (
      selectedVariant?.attributes?.categories.length &&
      selectedVariant?.attributes &&
      selectedVariant?.sku &&
      selectedVariant?.price?.centAmount &&
      productData?.name &&
      productData?.categories.length
    )
      gtmViewItem(productData, selectedVariant, qtyFromChild, account?.email);
  }, [selectedVariant]);

  useEffect(() => {
    setCurrentSlug((router?.query?.slug as any)?.join('/'));
  }, [router?.asPath]);

  useEffect(() => {
    if (selectedVariant?.sku) {
      setCurrentProductID('');
      const lastViewedVariant = localStorage?.getItem(CURRENTLY_SELECTED_VARIANT);

      if (lastViewedVariant && lastViewedVariant !== undefined) {
        const isRecentlyViewPresent: any = localStorage?.getItem(RECENTLY_VIEWED);
        let recentlyViewedArray: any[] = JSON.parse(isRecentlyViewPresent) || [];

        if (recentlyViewedArray[recentlyViewedArray?.length - 1]?.split(' ')[0] === lastViewedVariant?.split(' ')[0]) {
          recentlyViewedArray?.pop();
          recentlyViewedArray?.push(selectedVariant?.sku);
          localStorage.setItem(RECENTLY_VIEWED, JSON.stringify(recentlyViewedArray));
          setCurrentProductID(lastViewedVariant);
        } else if (lastViewedVariant !== undefined && recentlyViewedArray !== undefined) {
          const duplicateSKUIndex = recentlyViewedArray?.findIndex(
            (item) => item?.split(' ')[0] == lastViewedVariant?.split(' ')[0],
          );
          if (duplicateSKUIndex >= 0) {
            recentlyViewedArray?.splice(duplicateSKUIndex, 1);
          }
          recentlyViewedArray?.push(lastViewedVariant);
          if (recentlyViewedArray?.length > 5) {
            recentlyViewedArray?.shift();
          }
          recentlyViewedArray = recentlyViewedArray?.filter((obj) => obj != undefined);
          localStorage.setItem(RECENTLY_VIEWED, JSON.stringify(recentlyViewedArray));
          setCurrentProductID(lastViewedVariant);
        }
      }
      const recentlyViewedProductsFromLocalStorage = JSON.parse(localStorage.getItem(RECENTLY_VIEWED));
      if (recentlyViewedProductsFromLocalStorage?.length > 1) {
        setCanShowRecentlyViewed(true);
      } else {
        setCanShowRecentlyViewed(false);
      }

      setCurrentProductID(lastViewedVariant);
    }
  }, [selectedVariant]);

  const breadCrumbsDataForRichText = useMemo(() => {
    if (productDataSource && productDataSource?.categories) {
      return productDataSource?.categories?.[0]?.breadCrumb;
    }
    return null;
  }, [productDataSource]);

  return (
    <>
      {!isQuickView ? (
        <section className="mx-auto max-w-[1440px]">
          {/* <Breadcrumb breadcrumLevelLink={true} categories={productData?.categories} activePage={productData?.name} /> */}
          <Breadcrumbs
            createBreadCrumbFromUrlFlag={false}
            breadCrumbsData={breadCrumbData ? breadCrumbData : []}
            isPDP={true}
            productName={productData?.name}
            richTextData={breadCrumbsDataForRichText}
          />
        </section>
      ) : (
        <></>
      )}
      <section className="w-full px-4">
        <section className="mx-auto mb-10 mt-6 max-w-[1440px] lg:mt-1 lg:px-2">
          {isDesktop && !isQuickView && (
            <section className="mb-11">
              <PDPSocialLinks
                productId={productData?.productId}
                selectedVariant={selectedVariant}
                productName={productData?.name}
                selectedCarouselImage={selectedCarouselImage?.src}
              />
            </section>
          )}
          {productData !== null ? (
            <ImageGalleryCarousel
              isQuickView={isQuickView}
              productData={productData}
              setSelectedCarouselImage={setSelectedCarouselImage}
              selectedVariant={selectedVariant}
              setSelectedVariant={setSelectedVariant}
              optionalAccessory={optionalAccessory}
              closePlpQuickViewModal={closePlpQuickViewModal}
              pdpNavigationLink={pdpNavigationLink}
              isClearanceCategory={isClearanceCategory}
              sendQtyToParent={handleQtyFromChild}
            />
          ) : (
            !isQuickView && <section className="min-h-[100vh] md:min-h-[70vh] "></section>
          )}

          {!isDesktop && !isQuickView && (
            <section className="mt-11">
              <PDPSocialLinks
                productId={productData?.productId}
                selectedVariant={selectedVariant}
                productName={productData?.name}
                selectedCarouselImage={selectedCarouselImage?.src}
              />
            </section>
          )}
          {!isQuickView && (
            <ProductDescription
              productData={productData}
              variant={selectedVariant}
              dataSource={dataSource}
              trustPilotSkuId={trustPilotSkuId}
            />
          )}

          {!isQuickView && productData && term && isYMALEnabled && (
            <section className="border-t border-[#B19A6A] pt-[50px]">
              {isData && (
                <>
                  <strong className="mb-11 inline-block w-full text-[28px] font-semibold leading-[34px] text-sol-300">
                    {mapLocaleToMeaningfulFormat(router.locale).youMayAlsoLike}
                  </strong>
                </>
              )}
              <YouMayLike
                variantName={productData?.name}
                searchTerm={term}
                algoliaConfig={algolia}
                setIsData={setIsData}
              />
            </section>
          )}

          {!isQuickView && selectedVariant && (
            <section className="border-t border-[#B19A6A] pt-[50px]">
              {isShopData && collection && (
                <section className="flex">
                  <strong className="mb-11 inline-block w-full text-[28px] font-semibold leading-[34px] text-sol-300">
                    {mapLocaleToMeaningfulFormat(router.locale).shopCollection}
                  </strong>
                  <section className="mt-2 inline-block w-[80px] text-right hover:underline">
                    {isShowViewAll && (
                      <a href={`${routerURL.search}${collection ?? ''}`}>
                        {mapLocaleToMeaningfulFormat(router.locale).viewAll}
                      </a>
                    )}
                  </section>
                </section>
              )}
              {isShopCollectionEnabled && (
                <ShopCollection
                  variantName={productData?.name}
                  selectedVariant={selectedVariant}
                  algoliaConfig={algolia}
                  setCollection={setCollection}
                  setIsData={setIsShopData}
                  setIsShowViewAll={setIsShowViewAll}
                />
              )}
            </section>
          )}

          {/* {!isQuickView && canShowRecentlyViewed && (
            <section>
              <strong className="mb-11 inline-block w-full text-[28px] font-semibold leading-[34px] text-sol-300">
                {mapLocaleToMeaningfulFormat(router.locale).recentlyViewed}
              </strong>
              <section>
                <RecentlyViewed
                  currentProductID={currentProductID}
                  setCanShowRecentlyViewed={setCanShowRecentlyViewed}
                />
              </section>
            </section>
          )} */}
        </section>
      </section>
      <ProductRichText product={productDataSource} variant={productDataSource?.variants?.[0]} url={router.asPath} />
    </>
  );
};

export default PDP;
