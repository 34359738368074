import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import FacebookIcon from 'components/icons/sol/fb';
import HauzIcon from 'components/icons/sol/hauz-icon';
import MailIcon from 'components/icons/sol/mail-icon';
import PinterestIcon from 'components/icons/sol/pinterest';
import TwitterIcon from 'components/icons/sol/twitter';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import ShareProduct from './share-product';
import { gtmSocialMediaShare } from 'helpers/utils/gtm-events/gtmEcommerce';

interface PDPSocialLinksProps {
  selectedVariant?: any;
  productName?: string;
  selectedCarouselImage?: string;
  productId: string;
}
const PDPSocialLinks = ({ productId, productName, selectedVariant, selectedCarouselImage }: PDPSocialLinksProps) => {
  const router = useRouter();
  const [sharePdpProduct, setSharePdpProduct] = useState(false);
  const [copyURLData, setCopyURLData] = useState(false);
  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    const { pathname, origin } = window.location;
    setCurrentUrl(`${origin + pathname}`);
  }, [router.asPath]);

  const openShareProductModal = () => {
    setSharePdpProduct(true);
  };

  const closeShareProductModal = () => {
    setSharePdpProduct(false);
    setCopyURLData(false);
  };

  const copyURL = () => {
    setCopyURLData(true);
  };
  const openShareUrl = (shareUrlLink) => {
    window.open(shareUrlLink, '_blank', 'width=1000,height=475');
  };
  // Share URLs
  const shareUrlTwitter = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    currentUrl + '?color=' + selectedVariant?.attributes?.actualColor?.split(' ').join('+'),
  )}&text=${
    mapLocaleToMeaningfulFormat(router.locale).shareCheckOut +
    productName +
    mapLocaleToMeaningfulFormat(router.locale).fromShadesOfLight
  }`;
  const shareUrlFacebook = `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`;
  const shareUrlPinterest = `https://pinterest.com/pin/create/button/?url=${currentUrl}`;
  const shareUrlHouzz = `https://www.houzz.com/imageClipperUpload?imageUrl=${selectedCarouselImage}`;

  return (
    <>
      <section className="flex lg:justify-end">
        <section className="flex items-center gap-5">
          <p className="text-xs font-semibold leading-[15px] text-[#222222]">
            {mapLocaleToMeaningfulFormat(router.locale).share}:
          </p>
          <ul className="flex items-end gap-5">
            <li>
              <button
                type="button"
                className="hover:opacity-60"
                title={mapLocaleToMeaningfulFormat(router.locale).shareOnFacebook}
                aria-label={mapLocaleToMeaningfulFormat(router.locale).shareOnFacebook}
                onClick={() => {
                  gtmSocialMediaShare({
                    content_type: 'product',
                    item_id: productId,
                    method: 'Facebook',
                  });
                  openShareUrl(shareUrlFacebook);
                }}
              >
                <FacebookIcon className="fill-sol-300" />
              </button>
            </li>
            <li>
              <button
                type="button"
                className="hover:opacity-60"
                title={mapLocaleToMeaningfulFormat(router.locale).shareOnTwitter}
                aria-label={mapLocaleToMeaningfulFormat(router.locale).shareOnTwitter}
                onClick={() => {
                  gtmSocialMediaShare({
                    content_type: 'product',
                    item_id: productId,
                    method: 'Twitter',
                  });
                  openShareUrl(shareUrlTwitter);
                }}
              >
                <TwitterIcon className="fill-sol-300" />
              </button>
            </li>
            <li>
              <button
                type="button"
                className="hover:opacity-60"
                title={mapLocaleToMeaningfulFormat(router.locale).shareOnPinterest}
                aria-label={mapLocaleToMeaningfulFormat(router.locale).shareOnPinterest}
                onClick={() => {
                  gtmSocialMediaShare({
                    content_type: 'product',
                    item_id: productId,
                    method: 'Pinterest',
                  });
                  openShareUrl(shareUrlPinterest);
                }}
              >
                <PinterestIcon className="fill-sol-300" />
              </button>
            </li>
            <li>
              <button
                type="button"
                className="hover:opacity-60"
                title={mapLocaleToMeaningfulFormat(router.locale).shareOnHauzz}
                aria-label={mapLocaleToMeaningfulFormat(router.locale).shareOnHauzz}
                onClick={() => {
                  gtmSocialMediaShare({
                    content_type: 'product',
                    item_id: productId,
                    method: 'Houzz',
                  });
                  openShareUrl(shareUrlHouzz);
                }}
              >
                <HauzIcon className="fill-sol-300" />
              </button>
            </li>
            <li>
              <button
                type="button"
                className="hover:opacity-60"
                title={mapLocaleToMeaningfulFormat(router.locale).shareOnEmail}
                aria-label={mapLocaleToMeaningfulFormat(router.locale).shareOnEmail}
                id="shareProduct"
                onClick={() => {
                  gtmSocialMediaShare({
                    content_type: 'product',
                    item_id: productId,
                    method: 'Mail',
                  });
                  openShareProductModal();
                }}
              >
                <MailIcon className="mt-1 h-6 w-6 fill-sol-300" />
              </button>
            </li>
          </ul>
        </section>
        <ShareProduct
          modelOpen={sharePdpProduct}
          onClose={closeShareProductModal}
          shareProductHeading={mapLocaleToMeaningfulFormat(router.locale).shareByEmail}
          shareProductByLink={mapLocaleToMeaningfulFormat(router.locale).shareByLink}
          ShareProductByEmail={mapLocaleToMeaningfulFormat(router.locale).shareByEmail}
          url={currentUrl}
          copyURL={copyURL}
          btnContent={mapLocaleToMeaningfulFormat(router.locale).copy}
          linkCopy={copyURLData}
          shareButtonContent={mapLocaleToMeaningfulFormat(router.locale).share}
          cancelButtonContent={mapLocaleToMeaningfulFormat(router.locale).cancel}
        />
      </section>
    </>
  );
};

export default PDPSocialLinks;
