import NoSearchResults from 'components/sol-ui/products/no-search-results';
import Plp, { AlgoliaConfig } from 'components/sol-ui/products/plp';
import ProductSearchList from 'components/sol-ui/products/product-search';
import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { DataSourceExtractorHelpers } from 'helpers/utils/dataSourceExtractor';
import { DATA_SOURCE_LINKS } from 'helpers/utils/constant';
import _ from 'lodash'; // Import lodash for deep comparison
import { useSortBy } from 'react-instantsearch';
import { useIsMounted } from 'helpers/hooks/useIsMounted';
import useLocalStorage from 'helpers/hooks/useLocalStorage';
import { gtmApplyFilter } from 'helpers/utils/gtm-events/gtmEcommerce';

function CustomSortBy(props) {
  const { items, setGetCurrentIndex, getCurrentIndex, ...otherProps } = props;
  const { currentRefinement, options, refine } = useSortBy({ items, ...otherProps });
  const { setItem } = useLocalStorage();

  setItem('currentIndex', currentRefinement);
  return (
    <select
      onChange={(event) => {
        event.preventDefault();
        event.stopPropagation();
        refine(event.target.value);

        const optionSelected = options.find((option) => option.value === event.target.value);
        if (optionSelected) {
          gtmApplyFilter({
            filter_type: 'Sort',
            selected_option: optionSelected.label,
          });
        }
      }}
      value={currentRefinement}
    >
      {options.map((option) => (
        <option data-label={option.label} key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

const ProductListTastic = (data) => {
  const productListConfiguration = DataSourceExtractorHelpers.getPreloadedValue(
    DATA_SOURCE_LINKS.EMPTY_DATASOURCE,
    data?.pageFolder?.dataSourceConfigurations,
  );
  const route = useRouter();
  const isCategory = productListConfiguration?.route == 'category';

  const [facetsDisplay, setFacetsDisplay] = useState(null);
  const [showNoSearchFound, setShowNoSearchFound] = useState(false);
  const [isFiltersApplied, setIsFiltersApplied] = useState(false);
  const [canResetFilters, setCanResetFilters] = useState(false);

  const plpContentRegions = useMemo(() => {
    return data?.data?.data?.dataSource?.plpContentRegions?.plpContentRegion?.rawJson;
  }, [data?.data?.data.datasource]);

  useEffect(() => {
    setIsFiltersApplied(false);
    return () => {
      setShowNoSearchFound(false);
    };
  }, [route.asPath]);

  const facetJson = useMemo(() => {
    return JSON.parse(data?.data?.facetConfigurationJson)?.facetsConfig;
  }, [data?.data?.facetConfigurationJson]); // Optimize to only recompute when the JSON changes

  useEffect(() => {
    if (data && productListConfiguration && facetJson) {
      const urlCategory = productListConfiguration?.pathSlug?.replace('/categories/', '');
      let selectedCatFacetConfig =
        facetJson.find((item) => item?.category === urlCategory) ||
        facetJson.find((item) => item?.category === 'default');
      if (!_.isEqual(facetsDisplay, selectedCatFacetConfig?.facets)) {
        setFacetsDisplay(selectedCatFacetConfig?.facets);
      }
    }
  }, [data, productListConfiguration, facetJson]);

  const urlCategory = productListConfiguration?.pathSlug?.replace('/categories/', '');
  let selectedCatFacetConfig =
    facetJson.find((item) => item?.category === urlCategory) || facetJson.find((item) => item?.category === 'default');

  const memoizedProductSearchList = useMemo(() => {
    if (facetsDisplay) {
      const facetFilter = facetsDisplay.map((item) => item.facetKey);
      return (
        <ProductSearchList
          rawFacetsData={selectedCatFacetConfig}
          facetsDisplay={facetsDisplay}
          data={productListConfiguration}
          setShowNoSearchFound={setShowNoSearchFound}
          hitsPerPage={data?.data?.hitsPerPage}
          sortByData={data?.data?.sortBy}
          setIsFiltersApplied={setIsFiltersApplied}
          canResetFilters={canResetFilters}
          setCanResetFilters={setCanResetFilters}
          CustomSortBy={CustomSortBy}
          isSearchComponent={true}
          facetFilter={facetFilter}
        />
      );
    }
  }, [facetsDisplay]);

  return (
    <>
      {!showNoSearchFound ? (
        <>
          {isCategory ? (
            <Plp
              rawFacetsData={selectedCatFacetConfig}
              facetsDisplay={facetsDisplay}
              data={productListConfiguration}
              setShowNoSearchFound={setShowNoSearchFound}
              sortBy={data?.data?.sortBy}
              hitsPerPage={data?.data?.hitsPerPage}
              setIsFiltersApplied={setIsFiltersApplied}
              canResetFilters={canResetFilters}
              setCanResetFilters={setCanResetFilters}
              CustomSortBy={CustomSortBy}
              isSearchComponent={false}
              plpContentRegions={plpContentRegions}
            />
          ) : (
            memoizedProductSearchList
          )}
        </>
      ) : (
        <>
          <NoSearchResults
            title="data"
            data={data?.data?.dataSource}
            solInfo={data?.data}
            isFiltersApplied={isFiltersApplied}
            setShowNoSearchFound={setShowNoSearchFound}
            setCanResetFilters={setCanResetFilters}
            isYMALEnabled={data?.data?.isYMALEnabled}
          />
        </>
      )}
    </>
  );
};

export default ProductListTastic;
