import React, { useState, useEffect, useContext } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Cart, Cart as CartType } from '@Types/cart/Cart';
import { Discount } from '@Types/cart/Discount';
import { MdCheckCircleOutline } from 'react-icons/md';
import { CurrentPathContext } from 'helpers/continueShoppingContext/continueShoppingContext';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { gtmApplyPromoCode, gtmBeginCheckout } from 'helpers/utils/gtm-events/gtmEcommerce';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import { useAccount } from 'frontastic';
export interface CartSummaryProps {
  cart?: CartType;
  checkoutHeading?: string;
  showCalculateEstimatedTax?: boolean;
  showPromoCode: boolean;
  showCheckoutButton?: boolean;
  showHelpLinks?: boolean;
  redeemDiscountCode?: (couponCode: string) => Promise<Cart>;
  removeDiscountCode?: (discount: Discount) => Promise<void>;
  showShippingPrice?: boolean;
  showPromoRemoveButton?: boolean;
  content?: any;
  isCheckout: boolean;
  isCartValid: boolean;
  isCheckoutAddress: boolean;
}

function CartSummary({
  cart,
  checkoutHeading,
  showCalculateEstimatedTax,
  showPromoCode,
  showCheckoutButton,
  showHelpLinks,
  redeemDiscountCode,
  removeDiscountCode,
  showShippingPrice,
  showPromoRemoveButton,
  content,
  isCheckout,
  isCartValid,
  isCheckoutAddress,
}: CartSummaryProps) {
  const [couponCode, setCouponCode] = useState(null);
  const { account } = useAccount();
  const [checkPromoCode, setCheckPromoCode] = useState(false);
  const [checkPromoCodeValid, setCheckPromoCodeValid] = useState(true);
  const [promoCodeSuccess, setPromoCodeSuccess] = useState(false);
  const [discount, setDiscount] = useState<Discount>(
    cart?.discountCodes?.find((code) => code.state === 'MatchesCart') || undefined,
  );
  const [promoInputShow, setPromoInputShow] = useState(false);
  const router = useRouter();
  const { currentPath } = useContext(CurrentPathContext);

  const { loggedIn } = useAccount();
  function handlePromoClick(event) {
    event.preventDefault();
    setPromoInputShow(!promoInputShow);
    setPromoCodeSuccess(false);
    setCheckPromoCode(false);
    setCheckPromoCodeValid(true);
  }
  const handleRemovePromoCode = async (discount: Discount) => {
    try {
      if (discount?.discountId) {
        await removeDiscountCode(discount);
        setPromoInputShow(!promoInputShow);
        setPromoCodeSuccess(false);
        setCheckPromoCodeValid(false);
        if (!cart?.discountCodes?.find((code) => code.code === couponCode)) {
          setDiscount(undefined);
          setCouponCode('');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setDiscount(cart?.discountCodes?.find((code) => code.state === 'MatchesCart') || undefined);
  }, [cart]);

  useEffect(() => {
    if (promoCodeSuccess && couponCode) {
      gtmApplyPromoCode({
        promo_code: couponCode,
      });
    }
  }, [promoCodeSuccess, couponCode]);

  function handleCouponCode(event) {
    event.preventDefault();
    setCouponCode(event.target.value);
  }
  const handlePromoApply = async (event) => {
    event.preventDefault();
    if (couponCode == '') {
      setCheckPromoCode(true);
      setCheckPromoCodeValid(false);
    } else if (couponCode) {
      try {
        const addCoupon = await redeemDiscountCode(couponCode.toLowerCase() ? couponCode.toUpperCase() : couponCode);
        const responseDiscount = addCoupon?.discountCodes?.find((code) => code.state === 'MatchesCart');
        if (
          responseDiscount?.state === 'MatchesCart' &&
          responseDiscount?.code?.toUpperCase() === couponCode?.toUpperCase()
        ) {
          setCheckPromoCodeValid(true);
          setPromoCodeSuccess(true);
        } else {
          setCheckPromoCodeValid(false);
          setPromoCodeSuccess(false);
          setCheckPromoCode(true);
        }
      } catch (error) {
        console.log(error);
        setCheckPromoCodeValid(false);
        setPromoCodeSuccess(false);
        setCheckPromoCode(true);
      }
    } else {
      setCheckPromoCode(true);
      setCheckPromoCodeValid(false);
    }
    setCouponCode(null);
  };

  useEffect(() => {
    if (discount) {
      const { code, state } = discount;
      setCouponCode(code);
      setPromoInputShow(true);
      if ((state === 'MatchesCart' && code != '') || code?.toUpperCase() === couponCode?.toUpperCase()) {
        setCheckPromoCodeValid(true);
        setPromoCodeSuccess(true);
      } else {
        setCheckPromoCodeValid(false);
        setPromoCodeSuccess(false);
        setPromoInputShow(false);
      }
    }
  }, [cart, discount]);
  const handleCheckout = () => {
    gtmBeginCheckout(cart);
    if (loggedIn) {
      window.location.href = routerURL.checkoutAddress;
    } else {
      window.location.href = routerURL.checkout;
    }
  };

  return (
    <section className="shrink-0 lg:w-[21rem]">
      {cart?.lineItems?.length > 0 && (
        <section className="rounded-[0.375rem] bg-sol-100 p-3 pb-8 ">
          <h1 className="mb-6 text-base font-semibold leading-5 text-sol-300">{checkoutHeading}</h1>
          <section className="mb-4 flex justify-between">
            <p className="text-sm font-normal leading-[1.063rem] text-sol-300">
              {mapLocaleToMeaningfulFormat(router.locale).subTotal}
            </p>
            <p className="text-sm font-semibold leading-[1.063rem] text-sol-300">
              {CurrencyHelpers.formatForCurrency(cart?.subTotal)}
            </p>
          </section>
          {cart?.discountTotal && !account?.isB2BApproved ? (
            <section className="mb-4 flex justify-between">
              <p className="text-sm font-normal leading-[1.063rem] text-sol-300">
                {mapLocaleToMeaningfulFormat(router.locale).promoSavings}
              </p>
              <p className="text-sm font-semibold leading-[1.063rem] text-sol-300">
                {CurrencyHelpers.formatForDiscountCurrency(cart?.discountTotal)}
              </p>
            </section>
          ) : (
            <></>
          )}
          <section className="mb-4 flex justify-between">
            <p className="text-sm font-normal leading-[1.063rem] text-sol-300">
              {mapLocaleToMeaningfulFormat(router.locale).shipping}
            </p>
            {!showShippingPrice || cart?.specialShipping ? (
              <p className="text-sm font-semibold leading-[1.063rem] text-sol-300">
                {isCheckoutAddress || isCheckout
                  ? mapLocaleToMeaningfulFormat(router.locale).tbd
                  : mapLocaleToMeaningfulFormat(router.locale).calculatedAtCheckout}
              </p>
            ) : (
              <p className="text-sm font-semibold leading-[1.063rem] text-sol-300">
                {CurrencyHelpers.formatForCurrency(cart?.shippingAmount)}
              </p>
            )}
          </section>
          <section className="mb-4 flex justify-between">
            <p className="text-sm font-normal leading-[1.063rem] text-sol-300">
              {mapLocaleToMeaningfulFormat(router.locale).salesTax}{' '}
              {cart?.shippingAddress?.state === 'CO' && <span>*</span>}
            </p>
            {!showCalculateEstimatedTax ? (
              <p className="text-sm font-semibold leading-[1.063rem] text-sol-300">
                {isCheckoutAddress
                  ? mapLocaleToMeaningfulFormat(router.locale).tbd
                  : mapLocaleToMeaningfulFormat(router.locale).taxCalculateAtCheckout}
              </p>
            ) : (
              <p className="text-sm font-semibold leading-[1.063rem] text-sol-300">
                {CurrencyHelpers.formatForCurrency(cart?.totalTaxedPrice)}
              </p>
            )}
          </section>

          <section className="mt-6 flex justify-between">
            <p className="text-sm font-normal leading-[1.063rem] text-sol-300">
              {mapLocaleToMeaningfulFormat(router.locale).total}
            </p>
            <p className="text-sm font-semibold leading-[1.063rem] text-sol-300">
              {CurrencyHelpers.formatForCurrency(isCheckout ? cart?.total : cart?.cartTotal)}
            </p>
          </section>

          {showPromoCode && !account?.isB2BApproved && (
            <>
              {!promoInputShow && (
                <button
                  type="button"
                  onClick={handlePromoClick}
                  className="mt-7 text-sm font-medium text-sol-300 underline underline-offset-4"
                >
                  {mapLocaleToMeaningfulFormat(router.locale).havePromoCode}
                </button>
              )}
              {promoInputShow && (
                <section>
                  {!promoCodeSuccess && (
                    <>
                      <p className="mt-7 pb-2 text-sm font-medium text-sol-300">
                        {' '}
                        {mapLocaleToMeaningfulFormat(router.locale).enterPromoCode}
                      </p>
                      <section className="flex">
                        <section className="relative flex">
                          <input
                            type="search"
                            className={`rounded border-gray-400 outline-none ring-sol-300 focus:border-sol-300 focus:ring-sol-300 ${
                              checkPromoCodeValid
                                ? 'border-sol-300 text-sol-300 '
                                : 'border !border-red-500 text-sol-300 ring-red-500 focus:border-red-500 focus:ring-red-500'
                            } `}
                            onChange={handleCouponCode}
                            onKeyDown={(e) => e.key === 'Enter' && handlePromoApply(e)}
                          />
                        </section>
                        <button
                          className="pl-4 text-sm font-medium text-sol-300 underline underline-offset-4"
                          onClick={handlePromoApply}
                        >
                          {mapLocaleToMeaningfulFormat(router.locale).apply}
                        </button>
                      </section>
                    </>
                  )}
                  {promoCodeSuccess ? (
                    <section>
                      {discount?.code && (
                        <section className="mt-5 flex items-center text-sm text-sand-200">
                          <MdCheckCircleOutline className="mr-2 h-5 w-5" />
                          &#34;{discount?.code}&#34; {mapLocaleToMeaningfulFormat(router.locale).promoCodeApplied}
                        </section>
                      )}
                      {showPromoRemoveButton && (
                        <button
                          className="mt-3 text-sm font-medium text-sol-300 underline underline-offset-4 "
                          onClick={() => handleRemovePromoCode(discount)}
                        >
                          {mapLocaleToMeaningfulFormat(router.locale).remove}
                        </button>
                      )}
                    </section>
                  ) : (
                    checkPromoCode && (
                      <section className=" mt-2 text-sm font-medium text-red-500">
                        {mapLocaleToMeaningfulFormat(router.locale).invalidPromoCode}
                      </section>
                    )
                  )}
                </section>
              )}
            </>
          )}
          {showCheckoutButton && !isCheckoutAddress && (
            <>
              {!isCartValid ? (
                <section>
                  <button
                    type="submit"
                    disabled
                    className="btn-primary-large mt-7 mb-2 w-full cursor-not-allowed opacity-50"
                  >
                    {mapLocaleToMeaningfulFormat(router.locale).checkout}
                  </button>
                </section>
              ) : (
                <section>
                  <button type="submit" className="btn-primary-large mt-7 mb-2 w-full" onClick={handleCheckout}>
                    {mapLocaleToMeaningfulFormat(router.locale).checkout}
                  </button>
                </section>
              )}
              <section>
                <Link href={currentPath ? currentPath : routerURL?.home}>
                  <button
                    type="button"
                    id="continueShopping"
                    className="mt-0 h-11 w-full rounded border border-sol-500  py-3 px-4 text-sm font-bold text-sol-300 focus:outline-offset-2"
                  >
                    {mapLocaleToMeaningfulFormat(router.locale).continueShopping}
                  </button>
                </Link>
              </section>
            </>
          )}
          {/* this includes only for the CO state */}
          {router.asPath !== routerURL?.cart && (
            <section>
              {cart?.shippingAddress?.state === 'CO' && (
                <p className="mt-7 text-sm font-normal leading-[1.063rem] text-sol-300">
                  {mapLocaleToMeaningfulFormat(router.locale).coloradoDeliveryFee}
                </p>
              )}
            </section>
          )}
        </section>
      )}

      {content && <section className="mt-4 text-sm">{content}</section>}
      {router.asPath === routerURL?.cart
        ? null
        : showHelpLinks && (
            <section className=" mt-4 leading-8">
              {/* TODO STATIC - this will be replaced with dynamic on later  */}
              <Link href={routerURL?.contactUs}>
                <a className="text-sm text-sol-300 underline underline-offset-2">
                  {' '}
                  {mapLocaleToMeaningfulFormat(router.locale).contact}
                </a>
              </Link>
              <section>
                {/* TODO STATIC - this will be replaced with dynamic on later  */}
                <Link href={routerURL?.shippingInfo}>
                  <a className="text-sm text-sol-300 underline underline-offset-4">
                    {' '}
                    {mapLocaleToMeaningfulFormat(router.locale).shippingInformation}
                  </a>
                </Link>
              </section>
              <section>
                {/* TODO STATIC - this will be replaced with dynamic on later  */}
                <Link href={routerURL?.returnExchange}>
                  <a className="text-sm text-sol-300 underline underline-offset-4">
                    {' '}
                    {mapLocaleToMeaningfulFormat(router.locale).ReturnAndExchange}
                  </a>
                </Link>
              </section>
            </section>
          )}
    </section>
  );
}

export default CartSummary;
