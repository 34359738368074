import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { LineItem } from '@Types/cart/LineItem';
import { Money } from '@Types/product/Money';
import { LineItem as WishlistLineItem } from '@Types/wishlist/LineItem';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { LINEITEM_REMOVED, LINEITEM_UPDATED, LINEITEM_ADDED } from 'helpers/constants/messages';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { listrakSCAEvent } from 'helpers/listrakHelper';
import { useNotification } from 'helpers/notification/notificationContext';
import { totalPriceCalculation } from 'helpers/totalPriceCalculation';
import { AMPLIENCE_IMAGE_URL_FORMAT } from 'helpers/utils/constant';
import format from 'helpers/utils/format';
import calculateDynamicImageWidth from 'helpers/utils/getImageSize';
import { gtmQuantitySelector, gtmRemoveFromCart } from 'helpers/utils/gtm-events/gtmEcommerce';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import omitFileExtension from 'helpers/utils/omitFileExtension';
import { desktop } from 'helpers/utils/screensizes';
import { isAccessoryLinkDisabled } from 'helpers/utils/validateCart';
import NoImageAvailable from 'public/images/No-Image-Available.jpg';
import { useAccount, useCart, useConfig } from 'frontastic';
import Image from 'frontastic/lib/image';
interface MiniCartProps {
  lineItem: LineItem | WishlistLineItem;
  updateLineItem?: (lineItemId: string, count: number) => Promise<any>;
  removeLineItem?: (lineItemId: string) => Promise<any>;
  desktopModalClose?: () => void;
  mobileModalClose?: () => void;
  currentIndex?: any;
  setCurrentIndex?: (index: any) => void;
  lineItemIndex?: number;
  removeButtonDisabled?: boolean;
  setRemoveButtonDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  lineItemQuantityDisabled?: boolean;
  setLineItemQuantityDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
}
function MiniCart({
  lineItem,
  updateLineItem,
  removeLineItem,
  desktopModalClose,
  mobileModalClose,
  removeButtonDisabled,
  setRemoveButtonDisabled,
  lineItemQuantityDisabled,
  setLineItemQuantityDisabled,
  lineItemIndex,
}: MiniCartProps) {
  const router = useRouter();
  const { data: cart } = useCart();
  const { account } = useAccount();
  const { data: config } = useConfig();
  const { showMiniCartNotification, showNotification } = useNotification();
  const [isDesktop] = useMediaQuery(desktop);
  const [quantity, setQuantity] = useState(lineItem?.count || 0);
  const amountInCents = lineItem?.variant?.price?.centAmount * lineItem?.count;
  const [loading, setLoading] = useState(false);
  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const [availabilityMessage, setAvailabilityMessage] = useState(
    lineItem?.availabilityMessage && lineItem?.availabilityMessage,
  );
  const dynamicWidth = calculateDynamicImageWidth(100, 100, 100);
  const maxQuantityPerVariant = lineItem?.maxQuantityPerVariant;
  const isAccessoryDisabled = isAccessoryLinkDisabled(lineItem, config?.disabledAccessories);

  useEffect(() => {
    if (lineItem?.availabilityMessage) {
      setAvailabilityMessage(lineItem?.availabilityMessage);
    }
  }, [lineItem?.availabilityMessage]);
  const totalPrice =
    CurrencyHelpers.formatForCurrency({
      fractionDigits: lineItem?.variant?.price?.fractionDigits,
      centAmount: amountInCents,
      currencyCode: lineItem?.variant?.price?.currencyCode,
    } as Money) || 0;

  const handleRemoveLineitem = async ({ lineItemId }) => {
    if (lineItemId) {
      const updatedCart = await removeLineItem(lineItemId);
      gtmRemoveFromCart(lineItem, account);
      setRemoveButtonDisabled(false);
      setRemoveConfirmation(false);
      listrakSCAEvent(updatedCart, account);
      isDesktop ? showNotification(LINEITEM_REMOVED, 'success') : showMiniCartNotification(LINEITEM_REMOVED, 'success');
    }
  };
  useEffect(() => {
    setRemoveConfirmation(false);
  }, [cart?.lineItems]);

  const handleQuantity = async (e) => {
    const count = parseInt(e.target.value);
    if (!isNaN(count)) {
      if (count >= 1 && count <= maxQuantityPerVariant) {
        setQuantity(count);
      } else if (count === 0) {
        setQuantity(1);
      }
    } else {
      setQuantity(null);
    }
  };

  const incrementCount = async () => {
    const count = lineItem?.count + 1;
    if (count <= maxQuantityPerVariant) {
      setLoading(true);
      const updatedCart = await updateLineItem(lineItem?.lineItemId, count);
      setQuantity(count);
      isDesktop ? showNotification(LINEITEM_UPDATED, 'success') : showMiniCartNotification(LINEITEM_UPDATED, 'success');
      setLoading(false);
      listrakSCAEvent(updatedCart, account);
      gtmQuantitySelector({ clickAction: 'increase', lineItem, cartId: cart.cartId, source: 'mini-cart', account });
    }
  };
  const decrementCount = async () => {
    const count = lineItem?.count - 1;
    if (count > 0) {
      setLoading(true);
      const updatedCart = await updateLineItem(lineItem?.lineItemId, count);
      setQuantity(count);
      isDesktop ? showNotification(LINEITEM_UPDATED, 'success') : showMiniCartNotification(LINEITEM_UPDATED, 'success');
      setLoading(false);
      listrakSCAEvent(updatedCart, account);
      gtmQuantitySelector({ clickAction: 'decrease', lineItem, cartId: cart.cartId, source: 'mini-cart', account });
    }
  };

  const handleConfirmation = () => {
    setRemoveConfirmation(true);
    setRemoveButtonDisabled(true);
  };

  const handleCancel = () => {
    setRemoveConfirmation(false);
    setRemoveButtonDisabled(false);
  };

  useEffect(() => {
    setQuantity(lineItem.count);
  }, [lineItem?.count]);

  const handleUpdateQuantity = async () => {
    if (lineItem?.count != quantity) {
      setLineItemQuantityDisabled(true);
      if (!quantity) {
        setQuantity(1);
      }
      await updateLineItem(lineItem?.lineItemId, quantity);
      isDesktop ? showNotification(LINEITEM_UPDATED, 'success') : showMiniCartNotification(LINEITEM_UPDATED, 'success');
      setLineItemQuantityDisabled(false);
    }
  };
  const handleItemClick = () => {
    window.location.href = lineItem?._url;
  };

  return (
    <>
      <section>
        <section className="mt-2 grid gap-5 lg:grid-cols-5">
          <section className="col-span-5 flex gap-5 lg:col-span-3">
            <section>
              <section
                className="mr-4 w-[75px] shrink-0 rounded-md"
                onClick={isDesktop ? desktopModalClose : mobileModalClose}
              >
                {!isAccessoryDisabled ? (
                  <Link href={lineItem?._url ?? '#'}>
                    <a onClick={handleItemClick}>
                      <Image
                        src={format(AMPLIENCE_IMAGE_URL_FORMAT, [
                          omitFileExtension(lineItem?.variant?.images[0]?.url) || NoImageAvailable?.src,
                          dynamicWidth,
                        ])}
                        alt={lineItem?.name}
                        height={100}
                        width={100}
                        className="rounded-md"
                      />
                    </a>
                  </Link>
                ) : (
                  <Image
                    src={format(AMPLIENCE_IMAGE_URL_FORMAT, [
                      omitFileExtension(lineItem?.variant?.images[0]?.url) || NoImageAvailable?.src,
                      dynamicWidth,
                    ])}
                    alt={lineItem?.name}
                    height={100}
                    width={100}
                    className="rounded-md"
                  />
                )}
              </section>
            </section>
            <section>
              <section className="ml-2 text-sm font-normal leading-7 text-sol-300">
                {!isAccessoryDisabled ? (
                  <Link href={lineItem?._url ?? '#'}>
                    <a onClick={handleItemClick}>
                      <h1
                        className="cursor-pointer text-base font-semibold text-sol-300"
                        onClick={isDesktop ? desktopModalClose : mobileModalClose}
                      >
                        {lineItem?.name}
                      </h1>
                    </a>
                  </Link>
                ) : (
                  <h1 className="text-base font-semibold text-sol-300">{lineItem?.name}</h1>
                )}

                <p>{lineItem?.variant?.sku}</p>
                {lineItem?.variant?.attributes?.actualColor && (
                  <p>
                    <label>
                      {mapLocaleToMeaningfulFormat(router.locale).color}
                      {`${mapLocaleToMeaningfulFormat(router.locale).colon} ${''}`}
                    </label>
                    {lineItem?.variant?.attributes?.actualColor}
                  </p>
                )}
                {lineItem?.variant?.attributes?.filterFinish && (
                  <label>
                    {mapLocaleToMeaningfulFormat(router.locale).finish}
                    {`${mapLocaleToMeaningfulFormat(router.locale).colon} ${' '}`}
                  </label>
                )}
                {lineItem?.variant?.attributes?.filterFinish &&
                  lineItem?.variant?.attributes?.filterFinish.map((finish, index) => (
                    <span key={finish?.label}>
                      {index > 0 && ', '}
                      <span>{finish?.label}</span>
                    </span>
                  ))}

                {lineItem?.variant?.attributes?.actualSize &&
                  lineItem?.variant?.attributes?.categories &&
                  lineItem?.variant?.attributes?.categories[0] == 'Rugs' && ( //Size is only for Rugs
                    <p className="capitalize">
                      {mapLocaleToMeaningfulFormat(router.locale).size}
                      {mapLocaleToMeaningfulFormat(router.locale).colon} {lineItem?.variant?.attributes?.actualSize}
                    </p>
                  )}
                {lineItem?.isAvailable !== undefined && lineItem?.isAvailable ? (
                  <p className="font-semibold">{availabilityMessage || ''}</p>
                ) : (
                  <p className="text-sm font-semibold text-sol-700">
                    {mapLocaleToMeaningfulFormat(router.locale).notAvailable}
                  </p>
                )}
              </section>
            </section>
          </section>
          <section className="col-span-5 flex gap-[3.1rem] lg:col-span-2">
            <section>
              <p className="text-sm font-semibold text-sol-300">
                {mapLocaleToMeaningfulFormat(router.locale).quantity}
              </p>
              <section className="mt-2 flex w-[80px] items-center justify-between rounded-lg border">
                <section>
                  <input
                    id={`mini-cart-item-${lineItemIndex}`}
                    type="number"
                    value={quantity || ''}
                    disabled={!lineItem?.isAvailable || lineItemQuantityDisabled}
                    onBlur={handleUpdateQuantity}
                    onChange={handleQuantity}
                    className={`${
                      !lineItem?.isAvailable || lineItemQuantityDisabled
                        ? 'cursor-not-allowed opacity-50'
                        : 'cursor-pointer'
                    } input-number w-[53px] rounded-l-lg border-none text-center focus:border-none focus:outline-none focus:ring-0`}
                    min="1"
                    max={maxQuantityPerVariant}
                  />
                </section>
                <section className="flex h-[42px] flex-col items-center justify-center">
                  {!lineItem?.isAvailable ? (
                    <>
                      <button type="button" id="increaseQty" disabled={loading}>
                        <MdOutlineKeyboardArrowUp className="cursor-not-allowed pt-1 text-2xl opacity-50" />
                      </button>
                      <button>
                        <MdOutlineKeyboardArrowDown className="cursor-not-allowed pb-1 text-2xl opacity-50" />
                      </button>
                    </>
                  ) : (
                    <>
                      <button type="button" id="increaseQty" onClick={incrementCount} disabled={loading}>
                        <MdOutlineKeyboardArrowUp
                          className={`${
                            lineItem?.count >= maxQuantityPerVariant
                              ? 'cursor-not-allowed opacity-50'
                              : 'cursor-pointer'
                          } pt-1 text-2xl`}
                        />
                      </button>
                      {lineItem?.count > 1 ? (
                        <button onClick={decrementCount} disabled={loading}>
                          <MdOutlineKeyboardArrowDown className="pb-1 text-2xl" />
                        </button>
                      ) : (
                        <button>
                          <MdOutlineKeyboardArrowDown className="cursor-not-allowed pb-1 text-2xl opacity-50" />
                        </button>
                      )}
                    </>
                  )}
                </section>
              </section>
              {!removeConfirmation ? (
                <button
                  type="button"
                  id="miniCartRemove"
                  onClick={handleConfirmation}
                  className={`mt-4 mb-3 underline underline-offset-2 ${removeButtonDisabled && 'cursor-not-allowed'}`}
                  disabled={removeButtonDisabled}
                >
                  {mapLocaleToMeaningfulFormat(router.locale).remove}
                </button>
              ) : null}
            </section>
            {account && !account.isB2BApproved && lineItem?.originalPrice ? (
              <section>
                {
                  <strong className="mt-2 block text-left text-base font-normal leading-5 text-red-500 ">
                    {lineItem?.discountedPrice
                      ? totalPriceCalculation(lineItem?.discountedPrice, lineItem?.count)
                      : totalPriceCalculation(lineItem?.price, lineItem?.count)}
                  </strong>
                }
                <strong
                  className={`${
                    lineItem?.originalPrice && 'line-through'
                  } mt-2 block text-left text-base font-normal leading-5 text-sol-300`}
                >
                  {lineItem?.originalPrice
                    ? totalPriceCalculation(lineItem?.originalPrice, lineItem?.count)
                    : totalPriceCalculation(lineItem?.price, lineItem?.count)}
                </strong>
              </section>
            ) : account && account.isB2BApproved && lineItem?.originalPrice ? (
              <section>
                {
                  <strong className="mt-2 block text-left text-base font-normal leading-5 text-red-500 ">
                    {lineItem?.discountedPrice
                      ? totalPriceCalculation(lineItem?.discountedPrice, lineItem?.count)
                      : totalPriceCalculation(lineItem?.price, lineItem?.count)}
                  </strong>
                }
                <strong
                  className={`${
                    lineItem?.originalPrice && 'line-through'
                  } mt-2 block text-left text-base font-normal leading-5 text-sol-300`}
                >
                  {lineItem?.originalPrice
                    ? totalPriceCalculation(lineItem?.originalPrice, lineItem?.count)
                    : totalPriceCalculation(lineItem?.price, lineItem?.count)}
                </strong>
              </section>
            ) : (
              <section>
                {lineItem?.originalPrice ? (
                  <>
                    <p className="mt-2 text-base font-normal text-red-500">
                      {totalPriceCalculation(lineItem?.price, lineItem?.count)}
                    </p>
                    <p className="mt-2 text-base font-normal line-through">
                      {totalPriceCalculation(lineItem?.originalPrice, lineItem?.count)}
                    </p>
                  </>
                ) : (
                  <section className="mt-2 text-base font-normal">
                    {lineItem?.discountedPrice ? (
                      <section>
                        <p className="mt-2 text-base font-normal text-red-500">
                          {totalPriceCalculation(lineItem?.discountedPrice, lineItem?.count)}
                        </p>
                        <p className="mt-2 text-base font-normal line-through">
                          {totalPriceCalculation(lineItem?.price, lineItem?.count)}
                        </p>
                      </section>
                    ) : (
                      <p className="mt-2 text-base font-normal">
                        {totalPriceCalculation(lineItem?.price, lineItem?.count)}
                      </p>
                    )}
                  </section>
                )}
              </section>
            )}
          </section>
        </section>

        {removeConfirmation && (
          <>
            <div className="mt-2">
              <p className="text-base font-medium text-red-500">
                {mapLocaleToMeaningfulFormat(router.locale).sureWantToRemove}
              </p>
            </div>
            <section className=" my-1 flex flex-col gap-2 lg:my-4 lg:flex-row lg:gap-6">
              <button
                className="btn-primary-large px-1 font-bold text-white lg:px-16"
                id="removeLineItem"
                onClick={() => handleRemoveLineitem({ lineItemId: lineItem?.lineItemId })}
              >
                {mapLocaleToMeaningfulFormat(router.locale).remove}
              </button>
              <button
                className="rounded font-bold text-sol-300 underline underline-offset-2"
                id="cancelMiniCart"
                onClick={handleCancel}
              >
                {mapLocaleToMeaningfulFormat(router.locale).cancel}
              </button>
            </section>
          </>
        )}
      </section>
    </>
  );
}

export default MiniCart;
