import { Product } from '@Types/product/Product';
import CTProductListCard from 'components/sol-ui/products/ct-product-list/ct-product-list-card';
import { useEffect, useState } from 'react';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { useRouter } from 'next/router';
import useLocalStorage from 'helpers/hooks/useLocalStorage';
import { useProduct } from 'frontastic/provider';

function ShopCollectionTastic({ data }) {
  const router = useRouter();
  const [products, setProducts] = useState([] as Product[]);
  const { getItem } = useLocalStorage();
  const { getShopCollectionProducts } = useProduct();
  const [viewAll, setViewAll] = useState<string>();

  useEffect(() => {
    if (data?.data?.dataSource?.productShopCollection) {
      setProducts(data?.data?.dataSource?.productShopCollection);
      setViewAll(data?.data?.dataSource?.viewAll);
    }
  }, [data]);

  // useEffect(() => {
  //   if (getItem('pdpRoutePath') !== router?.asPath)
  //     getShopCollectionProducts(router.asPath).then((response: any) => {
  //       if (response) {
  //         setProducts(response);
  //       }
  //     });
  // }, [router.asPath]);

  return (
    <>
      {products && products.length ? (
        <section className="mx-auto mb-1 max-w-screen-8xl p-4">
          <div className="flex">
            <strong className="mt-5 mb-5 inline-block w-full text-[28px] font-semibold leading-[34px] text-sol-300">
              {mapLocaleToMeaningfulFormat(router.locale).shopCollection}
            </strong>

            {viewAll && products?.length === 4 && (
              <a href={viewAll}>
                <p className="mt-7 w-[5rem] hover:underline">{mapLocaleToMeaningfulFormat(router.locale).viewAll}</p>
              </a>
            )}
          </div>

          <section className="flex h-full w-full">
            <section className="w-full">
              <ul className="grid w-full grid-cols-2 gap-x-5 gap-y-[30px] md:gap-x-[27px] lg:grid-cols-4">
                {products.map((product, index) => (
                  <CTProductListCard data={product} key={index} positions={index} source="shop-collection" />
                ))}
              </ul>
            </section>
          </section>
        </section>
      ) : (
        <></>
      )}
    </>
  );
}

export default ShopCollectionTastic;
