import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import BreadcrumbRichText from 'components/seo/breadcrumbsRichText';
import { StringHelpers } from 'helpers/stringHelpers';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { routerURL } from 'helpers/utils/routerUrl';
import DoubleArrow from '../../../icons/sol/double-arrow';
import { gtmBreadcrumbClick } from 'helpers/utils/gtm-events/gtmEcommerce';
interface BreadcrumbProps {
  breadcrumLevelLink?: boolean;
  activePage?: string;
  categories?: any[];
  parentCategory?: string;
  parentOverrideUrl?: string;
}

const Breadcrumb = ({
  breadcrumLevelLink,
  categories,
  activePage,
  parentCategory = '',
  parentOverrideUrl = '',
}: BreadcrumbProps) => {
  const router = useRouter();
  const [breadcrumbs, setBreadcrumbs] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const category = categories ? categories[0] : undefined;
  const [activePageParent, setActivePageParent] = useState('') as any;

  useEffect(() => {
    if (parentCategory === '') {
      setActivePageParent('');
    } else setActivePageParent(StringHelpers.titleCaseFormatter(parentCategory));
  }, [parentCategory]);
  useEffect(() => {
    const tmpBreadcrumbs = [];
    tmpBreadcrumbs.push({
      title: mapLocaleToMeaningfulFormat(router.locale).home,
      ctaLink: routerURL?.home,
    });
    if (category && category?.breadCrumb) {
      category?.breadCrumb?.forEach((crumb: any, index: number) => {
        tmpBreadcrumbs.push({
          title: StringHelpers.titleCaseFormatter(crumb?.name)[0],
          ctaLink: crumb?.overrideURL ? crumb?.overrideURL : `/categories/${category?._url}`,
        });
      });
    } else if (!category && !breadcrumLevelLink) {
      const isOrderDetailsPage = activePage === mapLocaleToMeaningfulFormat(router.locale).orderDetails;
      tmpBreadcrumbs.push({
        title: mapLocaleToMeaningfulFormat(router.locale).account,
        ctaLink: routerURL?.account,
      });
      if (isOrderDetailsPage) {
        tmpBreadcrumbs.push({
          title: mapLocaleToMeaningfulFormat(router.locale).orderHistory,
          ctaLink: routerURL?.orderHistory,
        });
      }
    }
    if (parentCategory != '' && activePageParent != '') {
      tmpBreadcrumbs.push({
        title: activePageParent,
        ctaLink: parentOverrideUrl,
      });
    }
    tmpBreadcrumbs.push({
      title: activePage,
      ctaLink: activePage,
    });
    setBreadcrumbs(tmpBreadcrumbs);
    setLoading(false);
  }, [categories, activePage, breadcrumLevelLink, activePageParent, parentOverrideUrl]);
  return (
    <>
      <section
        id="pageBreadCrumbs"
        className="mx-auto mt-4 flex max-w-screen-8xl items-center text-xs font-normal text-sol-300"
      >
        <ul className="inline-block px-4 text-xs font-normal text-sol-300">
          {!loading &&
            breadcrumbs?.map((breadcrumb, idx) => (
              <li key={idx} className="inline leading-6">
                {idx > 0 && <DoubleArrow className="mx-2 inline" />}
                {idx < breadcrumbs.length - 1 && (
                  <Link href={breadcrumb?.ctaLink}>
                    <a
                      onClick={() => {
                        gtmBreadcrumbClick({
                          click_name: breadcrumb?.title,
                        });
                      }}
                      className="text-xs leading-[15px] text-sol-300"
                    >
                      {breadcrumb?.title}
                    </a>
                  </Link>
                )}
                {idx === breadcrumbs.length - 1 && (
                  <span className="text-xs leading-[15px] text-sol-300">{breadcrumb?.title}</span>
                )}
              </li>
            ))}
        </ul>
      </section>
      {!loading && <BreadcrumbRichText items={breadcrumbs} />}
    </>
  );
};

export default Breadcrumb;
