export const convertTitleFromCamelCase = (text: unknown) => {
  if (!text) return undefined;

  if (typeof text !== 'string') return undefined;

  if (conversionOverrides[text]) return conversionOverrides[text];

  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const conversionOverrides: Record<string, string> = {
  cri: 'CRI',
  backplateCanopySize: 'Backplate/Canopy Size',
  cfmwAirflowHigh: 'CFM/W Airflow High',
  cfmAirflowHigh: 'CFM Airflow High',
  cfmwAirflowLow: 'CFM/W Airflow Low',
  cfmAirflowLow: 'CFM Airflow Low',
} as const;
