/**
 * Google Tag Manager Ecommerce Events
 * TODO this needs to be refactored to use proper ecommerce
 * object structure and account for discounts currectly in the items[] array
 */

// these are used for purchase event hook
import { Discount } from '@Types/cart/Discount';
import { LineItem } from '@Types/cart/LineItem';
import { Order } from '@Types/cart/Order';
import { Cart, ProductList, Product } from '@Types/gtm-events/Ecommerce';
import { Money } from '@Types/product/Money';
import Cookies from 'js-cookie';
import { removeNonAlphanumericAndSpaces } from 'helpers/skuHelpers';
import { StringHelpers } from 'helpers/stringHelpers';
import { hydrateUserObj, slugToTitle } from 'helpers/utils/gtm-events/gtmHelpers';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { gtmTrackEvent, gtmTrackEventError } from 'helpers/utils/gtm-events/gtmTrackEvent';

interface ProductDataLayer {
  ecommerce?: {
    currency?: string;
    value?: number;
    source?: string;
    click_action?: string;
    cart_id?: string;
    items: [
      {
        item_id?: string;
        item_name?: string;
        item_list_name?: string;
        item_list_id?: string;
        index?: number;
        coupon?: string;
        discount?: number;
        item_brand?: string;
        item_category?: string;
        item_category2?: string;
        item_variant?: string;
        sale_status?: boolean;
        stock_status?: string;
        actual_size?: string;
        original_price?: number;
        price?: number;
        quantity?: number;
      },
    ];
  };
}

const isValidProduct = (product: any): boolean => {
  return product.item_id && product.item_name && product.price > 0;
};

// TODO apply this to the ecommerce objects as possible
const calculatePrice = (price: any): number => {
  return price?.centAmount / 10 ** price?.fractionDigits;
};

const mapLineItemsToProducts = (lineItems: LineItem[]): Product[] => {
  return lineItems.map((lineItem, i) => ({
    item_id: lineItem.variant.sku,
    item_name: lineItem.name,
    index: i,
    item_variant: lineItem.variant?.attributes?.collection,
    price: calculatePrice(lineItem),
    quantity: lineItem.count,
  }));
};

export const gtmEventAddToCart = ({
  product,
  variant,
  wishlistItems,
  itemQuantity,
  account,
}: {
  variant?: any;
  wishlistItems?: any;
  cart?: any;
  product?: any;
  itemQuantity?: number;
  account?: any;
}) => {
  const eventKey = 'add_to_cart';
  const eventObject: any = {};

  try {
    let quantity = 1;
    let price = 0;
    let originalPrice = 0;
    let items: any[] = [];

    if (product && variant) {
      quantity = itemQuantity || product?.count || 1;
      price = CurrencyHelpers.formatAmount(variant?.price ?? product?.price);
      originalPrice = CurrencyHelpers.formatAmount(
        variant?.originalPrice ?? product?.originalPrice ?? variant?.price ?? product?.price,
      );
      items = [
        {
          item_id: variant?.sku || '',
          item_name: product.name || '',
          coupon: product?.discountCodes?.[0]?.code || variant?.discountCodes?.[0]?.code || null,
          discount: Math.round((originalPrice - price) * 100) / 100,
          item_category: variant?.attributes?.categories?.[0] || '',
          item_category2: variant?.attributes?.categories?.[1] || null,
          item_variant: variant?.attributes?.actualColor?.trim() || '',
          actual_size: variant?.attributes?.actualSize || '',
          sale_status: variant?.isAvailable || product?.isAvailable || false,
          stock_status:
            variant?.inStock ||
            product?.inStock ||
            variant?.availableQuantity > 0 ||
            product?.availableQuantity > 0 ||
            product?.maxQuantityPerVariant > 0,
          price,
          original_price: originalPrice,
          quantity,
        },
      ];
    }

    if (wishlistItems?.length) {
      items = wishlistItems.map((product: any) => {
        quantity = product?.count || 1;
        price = CurrencyHelpers.formatAmount(product?.variant?.price ?? product?.price);
        originalPrice = CurrencyHelpers.formatAmount(
          product?.variant?.originalPrice ?? product?.originalPrice ?? product?.variant?.price ?? product?.price,
        );

        return {
          item_id: product?.variant?.sku || '',
          item_name: product.name || '',
          coupon: product?.discountCodes?.[0]?.code || product?.variant?.discountCodes?.[0]?.code || null,
          discount: Math.round((originalPrice - price) * 100) / 100,
          item_category: product?.variant?.attributes?.categories?.[0] || '',
          item_category2: product?.variant?.attributes?.categories?.[1] || null,
          item_variant: product?.variant?.attributes?.actualColor?.trim() || '',
          actual_size: product?.variant?.attributes?.actualSize || '',
          sale_status: product?.variant?.isAvailable || product?.isAvailable || false,
          stock_status:
            product?.variant?.inStock ||
            product?.inStock ||
            product?.variant?.availableQuantity > 0 ||
            product?.availableQuantity > 0 ||
            product?.maxQuantityPerVariant > 0,
          price,
          original_price: originalPrice,
          quantity,
        };
      });
    }

    if (items.length) {
      eventObject.ecommerce = {
        currency: product?.price?.currencyCode || variant?.price?.currencyCode || wishlistItems[0]?.price?.currencyCode,
        value: items.reduce((total, item) => total + item.price * item.quantity, 0),
        items,
      };

      const userObject = hydrateUserObj(account);
      if (userObject) {
        Object.assign(eventObject, { user: userObject });
      }

      if (eventObject?.ecommerce?.items.every(isValidProduct)) {
        gtmTrackEvent(eventKey, eventObject);
      } else {
        console.error('Invalid product in ', eventKey, ' event');
      }
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

export const gtmRemoveFromCart = (lineItem: any, account: any) => {
  const eventKey = 'remove_from_cart';

  const price = CurrencyHelpers.formatAmount(lineItem?.price);
  const originalPrice = CurrencyHelpers.formatAmount(lineItem?.originalPrice ?? lineItem?.price);

  try {
    const eventObject: ProductDataLayer = {
      ecommerce: {
        currency: lineItem?.price?.currencyCode,
        value: (lineItem.totalPrice.centAmount / 100) * lineItem.count,
        items: [
          {
            item_id: lineItem?.variant?.sku,
            item_name: lineItem.name,
            coupon: lineItem?.discountCodes?.[0]?.code || null,
            // item_list_id: itemData?.attributes?.categories[0], Doesn't appply for Cart
            // item_list_name: itemData?.attributes?.categories[0], Doesn't appply for Cart
            discount: Math.round((originalPrice - price) * 100) / 100,
            // index: variant?.index, Doesn't appply for Cart
            item_category: lineItem?.variant?.attributes?.categories[0],
            item_category2: lineItem?.variant?.attributes?.categories[1] || null,
            item_variant: lineItem?.variant?.attributes?.actualColor?.trim(),
            actual_size: lineItem?.variant?.attributes?.actualSize,
            sale_status: lineItem?.isAvailable || lineItem?.variant?.isAvailable,
            stock_status: lineItem?.inStock || lineItem?.variant?.inStock || lineItem?.variant?.availableQuantity > 0,
            price,
            original_price: originalPrice,
            quantity: lineItem.count,
          },
        ],
      },
    };

    // if we know the user email let's track them to the event
    const userObject = hydrateUserObj(account);
    if (userObject) {
      Object.assign(eventObject, { user: userObject });
    }

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

export const gtmQuantitySelector = ({
  clickAction,
  lineItem,
  source,
  account,
  cartId,
}: {
  clickAction: string;
  lineItem: any;
  source: string;
  account: any;
  cartId?: string;
}) => {
  const quantity = clickAction === 'increase' ? lineItem?.count + 1 : lineItem?.count - 1;
  const eventKey = 'quantity_selector';

  const price = lineItem?.price?.centAmount / 100;
  try {
    const eventObject: ProductDataLayer = {
      ecommerce: {
        click_action: clickAction,
        value: price * quantity,
        source,
        cart_id: source === 'cart' ? cartId : null,
        items: [
          {
            item_id: lineItem?.variant?.sku || lineItem?.sku,
            item_name: lineItem?.name,
            item_variant:
              lineItem?.variant?.attributes?.actualColor.trim() || lineItem?.attributes?.actualColor?.trim(),
            price,
            quantity,
          },
        ],
      },
    };

    // if we know the user email let's track them to the event
    const userObject = hydrateUserObj(account);
    if (userObject) {
      Object.assign(eventObject, { user: userObject });
    }

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

export const gtmViewCart = (cart: any, account?: any) => {
  const eventKey = 'view_cart';
  try {
    const eventObject: ProductDataLayer = {
      ecommerce: {
        currency: cart?.total?.currencyCode,
        value: cart.cartTotal.centAmount / 10 ** cart.cartTotal.fractionDigits,
        items:
          cart?.lineItems?.map((lineItem, i) => {
            const itemPrice = lineItem?.variant?.price.centAmount / 10 ** lineItem?.variant?.price.fractionDigits;
            const itemOriginalPrice = lineItem?.variant?.originalPrice?.centAmount
              ? lineItem?.variant?.originalPrice?.centAmount / 10 ** lineItem?.variant?.originalPrice?.fractionDigits
              : lineItem?.originalPrice?.centAmount
              ? lineItem?.originalPrice?.centAmount / 10 ** lineItem?.originalPrice?.fractionDigits
              : itemPrice;
            return <Product>{
              item_id: lineItem.variant.sku,
              item_name: lineItem.name,
              index: i,
              item_variant: lineItem.variant?.attributes?.actualColor.trim(),
              item_category: lineItem?.variant?.attributes?.categories[0],
              item_category2: lineItem?.variant?.attributes?.categories[1] || null, // It belongs to only one category
              actual_size: lineItem.variant?.attributes?.actualSize,
              sale_status: lineItem?.variant?.isAvailable,
              stock_status: lineItem?.variant?.inStock || lineItem?.inStock || lineItem?.variant?.availableQuantity > 0,
              price: itemPrice,
              discount: itemOriginalPrice !== itemPrice ? parseFloat((itemOriginalPrice - itemPrice).toFixed(2)) : 0,
              coupon: cart?.discountCodes[0]?.code || null,
              quantity: lineItem.count,
            };
          }) ?? [],
      },
    };

    // TODO refactor opportunity - this cjEvent should be pushed into GTM app to manage as a dataLayer variable
    const cjEventObject = {
      products: cart.lineItems.map((lineItem) => {
        return {
          productTitle: lineItem?.name,
          productType: lineItem?.productType,
          productSku: removeNonAlphanumericAndSpaces(lineItem?.variant?.sku),
          productPrice: lineItem?.price?.centAmount / 10 ** lineItem?.price?.fractionDigits,
          productQuantity: lineItem?.count,
          productDiscount:
            lineItem?.appliedDiscountPrice?.centAmount / 10 ** lineItem?.appliedDiscountPrice?.fractionDigits,
        };
      }),
    };

    // TODO this cjEvent should be pushed into GTM app to manage as a dataLayer variable
    Object.assign(eventObject, { cjEvent: cjEventObject });

    // if we know the user email let's track them to the event
    const userObject = hydrateUserObj(account);
    if (userObject) {
      Object.assign(eventObject, { user: userObject });
    }

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

export const gtmBeginCheckout = (cart: any) => {
  const eventKey = 'begin_checkout';
  try {
    const eventObject = {
      ecommerce: {
        currency: cart?.total?.currencyCode,
        value: cart.cartTotal.centAmount / 10 ** cart.cartTotal.fractionDigits,
        items: <ProductList>cart?.lineItems?.map((lineItem, i) => {
            return <Product>{
              item_id: lineItem.variant.sku,
              item_name: lineItem.name,
              index: i,
              item_variant: lineItem.variant?.attributes?.collection,
              // price: calculatePrice(variant?.discountedPrice || variant?.price),
              price:
                lineItem?.discountedPrice?.centAmount / 10 ** lineItem.price.fractionDigits ||
                lineItem.price.centAmount / 10 ** lineItem.price.fractionDigits,
              quantity: lineItem.count,
            };
          }) ?? [],
      },
    };

    // if we know the user email let's track them to the event
    if (cart?.email) {
      const userObject = {
        user: {
          email: StringHelpers?.convertToSHA256Hash(cart?.email),
        },
      };
      Object.assign(eventObject, userObject);
    }

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

export const gtmAddPaymentInfo = (cart: any) => {
  const eventKey = 'add_payment_info';
  try {
    const eventObject = {
      ecommerce: {
        currency: cart?.total?.currencyCode,
        value: cart?.total?.centAmount / 10 ** cart?.total?.fractionDigits,
        coupon: cart?.discountCodes[0]?.code || null,
        payment_type: 'Credit Card',
        items: <ProductList>cart?.lineItems?.map((lineItem, i) => {
            return <Product>{
              item_id: lineItem.variant.sku,
              item_name: lineItem.name,
              index: i,
              item_variant: lineItem.variant?.attributes?.collection,
              // price: calculatePrice(variant?.discountedPrice || variant?.price),
              price:
                lineItem?.discountedPrice?.centAmount / 10 ** lineItem.price.fractionDigits ||
                lineItem.price.centAmount / 10 ** lineItem.price.fractionDigits,
              quantity: lineItem.count,
            };
          }) ?? [],
      },
    };

    // if we know the user email let's track them to the event
    if (cart?.email) {
      const userObject = {
        user: {
          email: StringHelpers?.convertToSHA256Hash(cart?.email),
        },
      };
      Object.assign(eventObject, userObject);
    }

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

export const gtmAddShippingInfo = (cart: any) => {
  const eventKey = 'add_shipping_info';
  try {
    const eventObject = {
      ecommerce: {
        currency: cart?.total?.currencyCode,
        value: cart?.total?.centAmount / 10 ** cart?.total?.fractionDigits,
        coupon: cart?.discountCodes[0]?.code || null,
        shipping_tier: 'Ground',
        items: <ProductList>cart?.lineItems?.map((lineItem, i) => {
            return <Product>{
              item_id: lineItem.variant.sku,
              item_name: lineItem.name,
              index: i,
              item_variant: lineItem.variant?.attributes?.collection,
              // price: calculatePrice(variant?.discountedPrice || variant?.price),
              price:
                lineItem?.discountedPrice?.centAmount / 10 ** lineItem.price.fractionDigits ||
                lineItem.price.centAmount / 10 ** lineItem.price.fractionDigits,
              quantity: lineItem.count,
            };
          }) ?? [],
      },
    };

    // if we know the user email let's track them to the event
    if (cart?.email) {
      const userObject = {
        user: {
          email: StringHelpers?.convertToSHA256Hash(cart?.email),
        },
      };
      Object.assign(eventObject, userObject);
    }

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

export const gtmPurchase = (order: Order, account: any) => {
  // TODO fix in GTM since CCX used `ga4_purchase` we are migrating to `purchase`
  const eventKey = 'purchase';
  const cjeCookie = Cookies?.get('cje');
  const accumalateDiscountCodes = (discountCodes: Discount[]) => {
    let discountCode = '';
    if (discountCodes?.length > 0) {
      discountCodes?.map((discountCodeItem: Discount) => {
        discountCode = discountCode + ' ' + discountCodeItem?.code;
        return discountCode;
      });
    }
    return discountCode;
  };
  const formatPriceToDecimal = (price: Money) => {
    const numericStringValue = price ? parseFloat((price?.centAmount / 100).toString()) : 0.0;
    return parseFloat(numericStringValue.toFixed(2));
  };
  const calculateProductPrice = (lineItem: LineItem) => {
    if (account?.isB2BApproved) {
      return formatPriceToDecimal(lineItem?.discountedPrice ? lineItem?.discountedPrice : lineItem?.price);
    } else {
      return formatPriceToDecimal(lineItem?.price);
    }
  };
  const calculateDiscount = (lineItem: LineItem) => {
    let discount = 0;
    //TODO cage fight which is the better way to calculate the discount
    // discount = lineItem?.discounts?.reduce((acc, discount) => {
    //   return acc + formatPriceToDecimal(discount?.discountedAmount?.centAmount);
    // });
    discount = formatPriceToDecimal({
      centAmount: lineItem?.discountedPrice?.centAmount - lineItem?.price?.centAmount,
      fractionDigits: lineItem?.price?.fractionDigits,
      currencyCode: lineItem?.price?.currencyCode,
    });
    return discount;
  };
  const discountCoupon = accumalateDiscountCodes(order?.discountCodes);
  const discount = formatPriceToDecimal(order?.discountTotal);

  try {
    const eventObject = {
      ecommerce: {
        currency: order?.subTotal?.currencyCode,
        transaction_id: order?.orderNumber,
        value: order?.subTotal?.centAmount / 10 ** order?.subTotal?.fractionDigits,
        shipping: order?.shippingAmount?.centAmount / 10 ** order?.shippingAmount?.fractionDigits,
        tax: order?.totalTaxedPrice?.centAmount / 10 ** order?.totalTaxedPrice?.fractionDigits,
        coupon: order?.discountCodes?.[0]?.code || null,
        //TODO needs to account for promotion code + trade account
        discount: account?.isB2BApproved ? discount : 0,
        items: <ProductList>order?.lineItems?.map((lineItem, i) => {
            return <Product>{
              item_id: lineItem.variant.sku,
              item_name: lineItem.name,
              index: i,
              item_variant: lineItem.variant?.attributes?.collection,
              discount: calculateDiscount(lineItem),
              // discount: lineItem?.discounts?.discountedAmount || 0,
              original_price: calculatePrice(lineItem.variant?.price),
              price: calculatePrice(lineItem.variant?.discountedPrice || lineItem.variant?.price),
              // price:
              //   lineItem?.discountedPrice?.centAmount / 10 ** lineItem.price.fractionDigits ||
              //   lineItem.price.centAmount / 10 ** lineItem.price.fractionDigits,
              quantity: lineItem.count,
            };
          }) ?? [],
      },
    };
    const cjEventObj = {
      subTotal: formatPriceToDecimal(order?.subTotal),
      coupon: discountCoupon,
      discount: account?.isB2BApproved ? 0 : discount,
      orderId: order?.orderNumber,
      FIRECJ: cjeCookie || '',
      products:
        order.lineItems.map((lineItem) => {
          return {
            productTitle: lineItem?.name,
            productType: lineItem?.productType,
            productSku: removeNonAlphanumericAndSpaces(lineItem?.variant?.sku),
            productPrice: calculateProductPrice(lineItem),
            productQuantity: lineItem?.count,
          };
        }) ?? [],
    };

    // TODO this cjEvent should be pushed into GTM app to manage as a dataLayer variable
    Object.assign(eventObject, { cjEvent: cjEventObj });

    // if we know the user email let's track them to the event
    const userObject = hydrateUserObj(account);
    if (userObject) {
      Object.assign(eventObject, { user: userObject });
    }

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

// TODO this needs to be refactored for proper `add_to_wishlist` event
export const gtmSelectItemWishList = (wishlist: any, lineItem: any) => {
  const eventKey = 'select_item_wishlist';
  try {
    const eventObject = {
      ecommerce: {
        item_list_name: wishlist?.wishlistId,
        item_list_id: wishlist?.name,
        items: <ProductList>[
          <Product>{
            item_id: lineItem?.variant?.sku,
            item_name: lineItem?.name,
            item_variant: lineItem?.variant?.attributes?.collection,
            // price: calculatePrice(lineItem.variant?.discountedPrice || lineItem.variant?.price),
            price:
              lineItem?.variant?.discountedPrice?.centAmount / 10 ** lineItem?.variant?.price?.fractionDigits ||
              lineItem?.variant?.price?.centAmount / 10 ** lineItem?.variant?.price?.fractionDigits,
            quantity: lineItem?.count,
          },
        ],
      },
    };

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

export const gtmSelectItem = (
  productData: any,
  variant: any,
  itemListName = null,
  itemListId = null,
  productIndex: number,
  email?: string,
) => {
  const eventKey = 'select_item';

  let itemCategory;
  let itemCategory2;

  // for PLP productData.categories is an object that contains an array
  // for PDP productData.categories is an array
  if (productData?.categories['en-US']) {
    itemCategory = slugToTitle(productData?.categories['en-US'][0].slug);
    itemCategory2 = slugToTitle(productData?.categories['en-US'][1].slug);
  } else {
    itemCategory = slugToTitle(productData?.categories[0].name);
    itemCategory2 = slugToTitle(productData?.categories[1].name);
  }
  const price = productData?.price / 100 || variant.price.centAmount / 100;
  const originalPrice =
    productData?.originalPrice && productData?.originalPrice > 0
      ? productData?.originalPrice / 100
      : variant?.originalPrice?.centAmount && variant?.originalPrice?.centAmount > 0
      ? variant?.originalPrice?.centAmount / 100
      : 0;
  try {
    const eventObject: ProductDataLayer = {
      ecommerce: {
        currency: 'USD',
        value: price, // price * quantity, in this case quantity is 1
        items: [
          {
            item_id: productData.sku || variant.sku,
            item_name: productData.name['en-US'] || productData.name || variant.name,
            // coupon: productData?.discountCodes?.[0]?.code || null,  Doesn't appply for PLP
            item_list_id: itemListId,
            item_list_name: itemListName,
            discount: originalPrice > 0 ? parseFloat((originalPrice - price).toFixed(2)) : 0,
            index: productIndex,
            item_category: itemCategory,
            item_category2: itemCategory2,
            item_variant: variant?.all_Attributes?.actualColor?.trim() || variant?.attributes?.actualColor?.trim(),
            actual_size: variant?.attributes?.actualSize?.trim() || productData?.actualSize[0]?.trim(),
            sale_status: productData.isAvailable || productData?.shownOnSite,
            stock_status: variant?.inStock || variant?.availableQuantity > 0 || productData?.maxQuantityPerVariant > 0,
            price,
            original_price: originalPrice,
            quantity: 1,
          },
        ],
      },
    };

    // if we know the user email let's track them to the event
    if (email) {
      const userObject = {
        user: {
          email: StringHelpers?.convertToSHA256Hash(email),
        },
      };
      Object.assign(eventObject, userObject);
    }

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

interface MenuNavigationEvent {
  click_location: string;
  click_name: string;
}

export const gtmMenuNavigation = (event: MenuNavigationEvent) => {
  const eventKey = 'menu_navigation';

  try {
    const eventObject = {
      ecommerce: event,
    };

    gtmTrackEvent(eventKey, eventObject);
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

export const gtmViewItem = (productData: any, variant: any, quantity: number, email: string) => {
  const eventKey = 'view_item';

  const price = variant?.price?.centAmount / 100;
  const originalPrice = variant?.originalPrice?.centAmount ? variant?.originalPrice.centAmount / 100 : price;

  try {
    const eventObject: ProductDataLayer = {
      ecommerce: {
        currency: variant?.price?.currencyCode,
        value: price * quantity,
        items: [
          {
            item_id: variant.sku,
            item_name: productData.name,
            coupon: productData?.discountCodes?.[0]?.code || null,
            // item_list_id: itemData?.attributes?.categories[0], Doesn't appply for PDP
            // item_list_name: itemData?.attributes?.categories[0], Doesn't appply for PDP
            discount: originalPrice !== price ? parseFloat((originalPrice - price).toFixed(2)) : 0,
            // index: variant?.index, Doesn't appply for PDP
            item_category: productData?.categories[0].name,
            item_category2: productData?.categories[1].name,
            item_variant: variant?.attributes?.actualColor?.trim(),
            actual_size: variant?.attributes?.actualSize,
            sale_status: variant?.isAvailable,
            stock_status: variant?.inStock || variant?.availableQuantity > 0 || productData?.maxQuantityPerVariant > 0,
            price,
            original_price: originalPrice,
            quantity,
          },
        ],
      },
    };

    // if we know the user email let's track them to the event
    if (email) {
      const userObject = {
        user: {
          email: StringHelpers?.convertToSHA256Hash(email),
        },
      };
      Object.assign(eventObject, userObject);
    }

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

// TODO we need to track currency code
export const gtmViewItemList = (itemList: any) => {
  const eventKey = 'view_item_list';
  try {
    const eventObject = {
      ecommerce: {
        item_list_id: itemList?.wishlistId, // TODO research why is this using wishlist property naming
        item_list_name: itemList?.name,
        items: <ProductList>itemList?.lineItems?.map((lineItem, i) => {
            return <Product>{
              item_id: lineItem.variant.sku,
              item_name: lineItem.name,
              index: i,
              item_variant: lineItem.variant?.attributes?.collection,
              // price: calculatePrice(lineItem.variant?.discountedPrice || lineItem.variant?.price),
              price:
                lineItem?.discountedPrice?.centAmount / 10 ** lineItem.price.fractionDigits ||
                lineItem.price.centAmount / 10 ** lineItem.price.fractionDigits,
              quantity: lineItem.count,
            };
          }) ?? [],
      },
    };

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

export const gtmViewPromotion = (promo_name: string, product_id: any, itemList: any) => {
  const eventKey = 'view_promotion';
  try {
    const eventObject = {
      ecommerce: {
        promotion_id: 'P_' + product_id + '_related',
        promotion_name: promo_name,
        items: <ProductList>itemList?.lineItems?.map((lineItem, i) => {
            return <Product>{
              item_id: lineItem.variant.sku,
              item_name: lineItem.name,
              index: i,
              item_variant: lineItem.variant?.attributes?.collection,
              // price: calculatePrice(lineItem.variant?.discountedPrice || lineItem.variant?.price),
              price:
                lineItem?.discountedPrice?.centAmount / 10 ** lineItem.price.fractionDigits ||
                lineItem.price.centAmount / 10 ** lineItem.price.fractionDigits,
              quantity: lineItem.count,
            };
          }) ?? [],
      },
    };

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

export const gtmSelectPromotion = (promo_name: string, product_id: any, itemList: any) => {
  const eventKey = 'select_promotion';
  try {
    const eventObject = {
      ecommerce: {
        promotion_id: 'P_' + product_id + '_related',
        promotion_name: promo_name,
        items: <ProductList>itemList?.lineItems?.map((lineItem, i) => {
            return <Product>{
              item_id: lineItem.variant.sku,
              item_name: lineItem.name,
              index: i,
              item_variant: lineItem.variant?.attributes?.collection,
              // price: calculatePrice(lineItem.variant?.discountedPrice || lineItem.variant?.price),
              price:
                lineItem?.discountedPrice?.centAmount / 10 ** lineItem.price.fractionDigits ||
                lineItem.price.centAmount / 10 ** lineItem.price.fractionDigits,
              quantity: lineItem.count,
            };
          }) ?? [],
      },
    };

    if (eventObject.ecommerce.items.every(isValidProduct)) {
      gtmTrackEvent(eventKey, eventObject);
    } else {
      console.error('Invalid product in ', eventKey, ' event');
    }
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};
interface ZeroSearchResultsEvent {
  search_term: string;
}
export const gtmZeroSearchResults = (event: ZeroSearchResultsEvent) => {
  const eventKey = 'zero_search_results';
  try {
    const eventObject = {
      ecommerce: event,
    };
    gtmTrackEvent(eventKey, eventObject);
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

interface SocialMediaShareEvent {
  method: string;
  content_type: string;
  item_id: string;
}
export const gtmSocialMediaShare = (event: SocialMediaShareEvent) => {
  const eventKey = 'share';

  try {
    const eventObject = {
      ecommerce: event,
    };
    gtmTrackEvent(eventKey, eventObject);
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

interface ApplyPromoCodeEvent {
  promo_code: string;
}
export const gtmApplyPromoCode = (event: ApplyPromoCodeEvent) => {
  const eventKey = 'apply_promo_code';
  try {
    const eventObject = {
      ecommerce: event,
    };
    gtmTrackEvent(eventKey, eventObject);
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

interface BreadcrumbClickEvent {
  click_name: string;
}
export const gtmBreadcrumbClick = (event: BreadcrumbClickEvent) => {
  const eventKey = 'breadcrumb_click';

  try {
    const eventObject = {
      ecommerce: event,
    };
    gtmTrackEvent(eventKey, eventObject);
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

interface ImageScrollEvent {
  click_action: string;
  item_id: string;
  media_name: string;
}
export const gtmImageScroll = (event: ImageScrollEvent) => {
  const eventKey = 'image_scroll';

  try {
    const eventObject = {
      ecommerce: event,
    };
    gtmTrackEvent(eventKey, eventObject);
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};

interface ApplyFilterEvent {
  filter_type: string;
  selected_option: string;
}

export const gtmApplyFilter = (event: ApplyFilterEvent) => {
  const eventKey = 'apply_filter';
  try {
    const eventObject = {
      ecommerce: event,
    };
    gtmTrackEvent(eventKey, eventObject);
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};
interface AccordionInteractionEvent {
  click_action: string;
  interaction_text: string;
  interaction_location: string;
}
export const gtmAccordionInteraction = (event: AccordionInteractionEvent) => {
  const eventKey = 'accordion_interaction';

  try {
    const eventObject = {
      ecommerce: event,
    };

    gtmTrackEvent(eventKey, eventObject);
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};
